export class DateUtils {
    static get today() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    }
    static dateToString(date) {
        if (date == null) {
            return null;
        }
        const year = date.getFullYear().toString();
        return (year +
            "-" +
            this.withLeadingZero(date.getMonth() + 1) +
            "-" +
            this.withLeadingZero(date.getDate()));
    }
    static isAfterMonth(date, month) {
        return DateUtils.isAfterDate(date, month) && !DateUtils.isSameMonth(date, month);
    }
    static max(first, second) {
        return this.isAfterDate(first, second) ? first : second;
    }
    static min(first, second) {
        return this.isBeforeDate(first, second) ? first : second;
    }
    static get minDate() {
        return new Date(-MAX_TIMESTAMP);
    }
    static get maxDate() {
        return new Date(MAX_TIMESTAMP);
    }
    static clamp(date, min, max) {
        if (!this.isValidDate(min)) {
            min = this.minDate;
        }
        if (!this.isValidDate(max)) {
            max = this.maxDate;
        }
        if (this.isBeforeDate(max, min)) {
            throw new Error(`Clamp: min cannot be greater then max.\nInput: min = ${min}, max = ${max}`);
        }
        return this.min(max, this.max(min, date));
    }
    static newDateFromString(dateString) {
        if (dateString == null || dateString.length === 0) {
            return null;
        }
        const date = new Date(dateString);
        date.setHours(0, 0, 0, 0);
        return date;
    }
    static newDate(year, month, day) {
        const date = new Date();
        date.setFullYear(year, month !== null && month !== void 0 ? month : 0, day !== null && day !== void 0 ? day : 1);
        date.setHours(0, 0, 0, 0);
        return date;
    }
    static stringToDate(dateString) {
        if (dateString == null) {
            return null;
        }
        return this.newDateFromString(dateString);
    }
    static withLeadingZero(n) {
        if (n == null || isNaN(n)) {
            return undefined;
        }
        if (Math.abs(n) >= 10) {
            return `${n}`;
        }
        else if (n >= 0) {
            return `0${n}`;
        }
        else {
            return `-0${n * -1}`;
        }
    }
    static withMultipleLeadingZeros(n, length) {
        if (isNaN(n) || isNaN(length)) {
            return undefined;
        }
        if (length <= 0) {
            return n.toString();
        }
        const absolute = Math.abs(n);
        const zeros = Math.max(length - absolute.toString().length, 0);
        const result = "0".repeat(zeros) + absolute.toString();
        return n < 0 ? `-${result}` : result;
    }
    static getMondayOfWeek(dayOfWeek) {
        let daysAfterMondayOfThisWeek = dayOfWeek.getDay() - 1; // => 0=monday ... 6=sunday
        if (daysAfterMondayOfThisWeek == -1) {
            // sunday
            daysAfterMondayOfThisWeek = 6;
        }
        return this.newDate(dayOfWeek.getFullYear(), dayOfWeek.getMonth(), dayOfWeek.getDate() - daysAfterMondayOfThisWeek);
    }
    static getSundayOfWeek(dayOfWeek) {
        return DateUtils.addDays(this.getMondayOfWeek(dayOfWeek), 6);
    }
    static getPreviousMonth(date) {
        return this.newDate(date.getFullYear(), date.getMonth() - 1, 1);
    }
    static getNextMonth(date) {
        return this.newDate(date.getFullYear(), date.getMonth() + 1, 1);
    }
    static isBeforeMonth(date, month) {
        return DateUtils.isBeforeDate(date, month) && !DateUtils.isSameMonth(date, month);
    }
    static isBeforeOrSameMonth(date, month) {
        return this.isBeforeMonth(date, month) || this.isSameMonth(date, month);
    }
    static isAfterOrSameMonth(date, month) {
        return this.isAfterMonth(date, month) || this.isSameMonth(date, month);
    }
    static isAfterDate(date1, date2) {
        return (date1 === null || date1 === void 0 ? void 0 : date1.getTime()) > (date2 === null || date2 === void 0 ? void 0 : date2.getTime());
    }
    static isAfterOrSameDate(date1, date2) {
        if (date1 == null || date2 == null) {
            return false;
        }
        return date1.getTime() >= date2.getTime();
    }
    static isBetweenDate(date, startDate, endDate) {
        if (date == null || startDate == null || endDate == null) {
            return false;
        }
        return DateUtils.isAfterDate(date, startDate) && DateUtils.isBeforeDate(date, endDate);
    }
    static isBeforeDate(date1, date2) {
        return (date1 === null || date1 === void 0 ? void 0 : date1.getTime()) < (date2 === null || date2 === void 0 ? void 0 : date2.getTime());
    }
    static isBeforeOrSameDate(date1, date2) {
        if (date1 == null || date2 == null) {
            return false;
        }
        return date1.getTime() <= date2.getTime();
    }
    static getWeekdayName(date) {
        return WEEKDAY_NAMES[date.getDay()];
    }
    static getShortWeekdayNames() {
        return WEEKDAY_NAMES.concat(WEEKDAY_NAMES[0])
            .slice(
        // Put "Sonntag" at the end
        1, 8)
            .map((weekday) => weekday.substr(0, 2));
    }
    static isValidDate(date) {
        return date != null && !isNaN(date.getTime());
    }
    static addDays(date, numberOfDays) {
        const newDate = this.newDate(date.getFullYear(), date.getMonth(), date.getDate() + numberOfDays);
        newDate.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        date.setTime(+newDate);
        return date;
    }
    static addMinutes(date, numberOfMinutes) {
        const newDate = this.newDate(date.getFullYear(), date.getMonth(), date.getDate());
        newDate.setHours(date.getHours(), date.getMinutes() + numberOfMinutes, date.getSeconds(), date.getMilliseconds());
        date.setTime(+newDate);
        return date;
    }
    static isSameMonth(date1, date2) {
        if (date1 == null || date2 == null) {
            return false;
        }
        return date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear();
    }
    static getFirstDayOfMonth(date) {
        return this.newDate(date.getFullYear(), date.getMonth(), 1);
    }
    static getLastDayOfMonth(date) {
        return this.newDate(date.getFullYear(), date.getMonth() + 1, 0);
    }
    static getFirstDayOfYear(date) {
        return this.newDate(date.getFullYear(), 0, 1);
    }
    static getLastDayOfYear(date) {
        return this.newDate(date.getFullYear() + 1, 0, 0);
    }
    static isSameDate(date, otherDate) {
        return (date != null &&
            otherDate != null &&
            date.getFullYear() == otherDate.getFullYear() &&
            date.getMonth() == otherDate.getMonth() &&
            date.getDate() == otherDate.getDate());
    }
    static getFormattedDisplayDate(date) {
        const d = new Date(date);
        return (DateUtils.withLeadingZero(d.getDate()) +
            "." +
            DateUtils.withLeadingZero(d.getMonth() + 1) +
            "." +
            this.withMultipleLeadingZeros(d.getFullYear(), 4));
    }
    static getFormattedDisplayDateFrom(year, month, day) {
        return ((day != null ? DateUtils.withLeadingZero(day) + "." : "") +
            (month != null ? DateUtils.withLeadingZero(month) + "." : "") +
            (year != null ? DateUtils.withLeadingZero(year) : ""));
    }
}
DateUtils.secondsOfADay = 24 * 60 * 60;
DateUtils.millisecondsOfADay = 1000 * DateUtils.secondsOfADay;
const MAX_TIMESTAMP = 8640000000000000;
const WEEKDAY_NAMES = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
];
export const MONTH_NAMES = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
];
export const MONTH_NAMES_SHORT = [
    "Jan",
    "Feb",
    "März",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Okt",
    "Nov",
    "Dez",
];
export function toMonthYear(date) {
    return date != undefined
        ? {
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        }
        : undefined;
}
export function isSameMonthYear(monthYear, otherMonthYear) {
    return monthYear.year == otherMonthYear.year && monthYear.month == otherMonthYear.month;
}
