export const DATE_CALENDAR_MIN_HEIGHT = 388;
export const DATE_CALENDAR_MAX_HEIGHT = 444;
export var InputControllerType;
(function (InputControllerType) {
    InputControllerType[InputControllerType["DAY"] = 0] = "DAY";
    InputControllerType[InputControllerType["MONTH"] = 1] = "MONTH";
    InputControllerType[InputControllerType["YEAR"] = 2] = "YEAR";
})(InputControllerType || (InputControllerType = {}));
export const DATE_SEGMENTS = ["day", "month", "year"];
export const TIME_SEGMENTS = ["minute", "hour"];
